.raise {
  padding-top: 50px;
  padding-bottom: 55px;

  background-color: $blue-light;

  color: $blue-dark;

  @include min($desktop) {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

.raise-container {

}

.raise-brow {
  margin-bottom: 35px;

  font-weight: 500;
  font-size: 1.5rem;
  @include letter-spacing(50);
  line-height: 1.6;
  text-transform: uppercase;

  @include min($desktop) {
    margin-bottom: 60px;

    font-size: 2.1rem;
    line-height: 1.4;
  }
}

.raise-heading {
  font-size: 2.2rem;
  @include letter-spacing(-15);
  line-height: 1.35;

  @include min($desktop) {
    font-size: 3.9rem;
    @include letter-spacing(-25);
    line-height: 1.2;
  }
}

  // each

  .raise-heading-1 {
    margin-bottom: 30px;

    @include min($desktop) {
      margin-bottom: 50px;
    }
  }
