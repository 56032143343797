*:where(:not(svg, svg *)) {
  all: unset;
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}
