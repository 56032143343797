.loader {
  overflow: hidden;
  visibility: hidden;

  transition-property: visibility;
  transition-duration: 0s;
  transition-timing-function: linear;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

  // states

  .loader.is-open {
    visibility: visible;

    transition-delay: 0s;
  }

  .loader.is-closed {
    visibility: hidden;

    transition-delay: .5s;
  }

.loader-bar {
  transition: clip-path $duration $easing;

  position: absolute;
  top: 0;

  width: calc(20% + 1px);
  height: 100%;

  background-color: $blue-dark;

  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

  // states

  .loader.is-open .loader-bar {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .loader.is-closed .loader-bar {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }

  // delays

  .loader.is-open {
    .loader-bar-1 { transition-delay: 0s; }
    .loader-bar-2 { transition-delay: .05s; }
    .loader-bar-3 { transition-delay: .10s; }
    .loader-bar-4 { transition-delay: .15s; }
    .loader-bar-5 { transition-delay: .2s; }
  }

  .loader.is-closed {
    .loader-bar-1 { transition-delay: .2s; }
    .loader-bar-2 { transition-delay: .15s; }
    .loader-bar-3 { transition-delay: .10s; }
    .loader-bar-4 { transition-delay: .05s; }
    .loader-bar-5 { transition-delay: 0s; }
  }

  // positioning

  .loader-bar-1 { left: 0; }
  .loader-bar-2 { left: 20%; }
  .loader-bar-3 { left: 40%; }
  .loader-bar-4 { left: 60%; }
  .loader-bar-5 { left: 80%; }

  // bg image

  .loader-bar-3 {
    background-image: url("../assets/images/components/loader/icon.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
