.nav {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  padding-top: 30px;

  @include min($desktop) {
    padding-top: 35px;
  }

  @include min($cinema) {
    position: fixed;
    top: 0;
    left: 0;

    width: auto;
  }
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include min($cinema) {
    max-width: none;
    width: auto;
    padding-left: 35px;
  }
}

.nav-logo {
  display: block;
  width: 59px;
  height: 15px;

  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  @include image-replace;

  cursor: pointer;
}

  // bg image

  .nav-dark .nav-logo  { background-image: url("../assets/images/components/nav/logo-dark.svg"); }
  .nav-light .nav-logo { background-image: url("../assets/images/components/nav/logo-light.svg"); }

.nav-switch {
  transition: transform $duration $easing;

  position: relative;

  width: 28px;
  height: 16px;

  cursor: pointer;

  @include min($cinema) {
    display: none;
  }
}

  // active

  .nav-switch.is-active {
    transform: translate3d(7px, 0, 0);
  }

.nav-switch-line {
  transition: transform $duration $easing;

  position: absolute;
  left: 0;

  width: 100%;
  height: 1px;
}

  // active

  .nav-switch.is-active {
    .nav-switch-line-top { transform: rotate(45deg) translate3d(0, 5px, 0); }
    .nav-switch-line-bot { transform: rotate(-225deg) translate3d(0, 5px, 0); }
  }

  // bg color

  .nav-dark .nav-switch-line  { background-color: $blue-dark; }
  .nav-light .nav-switch-line { background-color: $gray; }

  // each

  .nav-switch-line-top { top: 4px; }
  .nav-switch-line-bot { bottom: 4px; }
