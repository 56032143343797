@keyframes arrow {
  0%, 50%, 100% { transform: translate3d(0, 0, 0); }
  25%           { transform: translate3d(0, -2px, 0); }
  75%           { transform: translate3d(0, 2px, 0); }
}

@keyframes heroDisclaimer {
  0% {
    opacity: 0;
    transform: translate3d(0, 4vh, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rotate {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
