.about {
  background-color: $blue-dark;

  color: $gray;
}

.about {
  @import "hero";
  @import "relations";
  @import "speed";
}
