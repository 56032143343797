$container-max-width: 1024px;

$tablet:  768px;
$desktop: 1024px;
$cinema:  1366px;

$serif: "Freight", serif;
$sans:  "Indivisible", sans-serif;

$blue-light: #91ADBC;
$blue-dark:  #0C1923;
$gray:       #D5D8E0;
$green:      #64E27C;

$duration: .3s;
$easing: ease-in-out;
