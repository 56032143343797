.membership {
  margin-bottom: 60px;

  @include min($desktop) {
    margin-bottom: 120px;
  }
}

.membership-container {

  @include min($tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5%;
  }
}

.membership-heading {
  margin-bottom: 50px;

  font-size: 2.2rem;
  @include letter-spacing(-15);
  line-height: 1.35;

  @include min($tablet) {
    margin-bottom: 0;
  }

  @include min($desktop) {
    font-size: 3.9rem;
    @include letter-spacing(-25);
    line-height: 1.2;
  }
}

.membership-list {

}

.membership-item {
  position: relative;

  padding-top: 30px;
  padding-bottom: 30px;

  @include min($desktop) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

  // gradient

  .membership-item::before {
    content: "";

    transform: scale3d(0, 1, 1);
    transform-origin: left center;
    transition: transform $duration $easing;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 1px;

    background-image: linear-gradient(-90deg, #0C1923 0%, #868D9E 100%);
  }

  .membership-item.is-inview::before {
    transform: scale3d(1, 1, 1);
  }

  // last

  .membership-item:last-child {
    padding-bottom: 0;
  }

.membership-icon {
  margin-bottom: 25px;
}

.membership-range {
  font-size: 1.8rem;
  @include letter-spacing(-15);
  line-height: 1.5;

  @include min($desktop) {
    font-weight: 500;
    font-size: 2.4rem;
    @include letter-spacing(-25);
    line-height: 1.05;
  }
}
