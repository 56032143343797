.hero {
  height: 100vh;
  height: 100svh;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .webp &    { background-image: url("../assets/images/pages/about/hero/bg-small.jpg?as=webp"); }
  .no-webp & { background-image: url("../assets/images/pages/about/hero/bg-small.jpg"); }

  @include min($tablet) {

    .webp &    { background-image: url("../assets/images/pages/about/hero/bg-large.jpg?as=webp"); }
    .no-webp & { background-image: url("../assets/images/pages/about/hero/bg-large.jpg"); }
  }
}

.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.hero-heading {
  margin-bottom: 25px;

  font-size: 2.8rem;
  @include letter-spacing(-15);
  line-height: 1.3;

  @include min($desktop) {
    margin-bottom: 30px;

    font-size: 5.8rem;
    line-height: 1.15;
  }
}

.hero-button {
  display: flex;
  align-items: center;

  cursor: pointer;

  @include min($cinema) {
    transition-timing-function: ease-out;
    transition-delay: .25s + (.05s * 3); // same as .line-content-4
  }
}

.hero-button-icon {
  animation: arrow 1s linear infinite;

  width: 18px;
  margin-right: 15px;

  @include min($desktop) {
    width: 24px;
    margin-right: 20px;
  }
}

.hero-button-text {
  font-family: $serif;
  font-weight: 300;
  font-size: 1.6rem;
  @include letter-spacing(-15);
  line-height: 1;

  @include min($desktop) {
    font-size: 2rem;
  }
}
