// INVIEW

.inview {
  opacity: 0;

  transition: opacity $duration $easing, transform $duration $easing;
}

  // direction

  .inview-up    { transform: translate3d(0, 4vh, 0); }
  .inview-down  { transform: translate3d(0, -4vh, 0); }
  .inview-right { transform: translate3d(-4vh, 0, 0); }
  .inview-left  { transform: translate3d(4vh, 0, 0); }

  // inview

  .inview.is-inview {
    opacity: 1;

    transform: translate3d(0, 0, 0);
  }
