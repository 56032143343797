.underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 1px;
  text-underline-position: under;
}

  // colors

  .underline-light { text-decoration-color: $green; }
  .underline-dark  { text-decoration-color: $blue-dark; }

@include min($cinema) {
  .underline {
    position: relative;

    display: inline-block;

    text-decoration: none;
  }

    // active

    .is-inview .underline::after {
      transform: scale3d(1, 1, 1);
    }

    // after

    .underline::after {
      content: "";

      transform: scale3d(0, 1, 1);
      transform-origin: left center;
      transition: transform $duration $easing $duration;

      position: absolute;
      bottom: 0;
      left: 0;

      display: block;
      width: 100%;
      height: 1px;
    }

    .underline-light::after { background-color: $green; }
    .underline-dark::after  { background-color: $blue-dark; }

    // delays

    .line-content-2 .underline::after { transition-delay: .25s + (.05s * 1) + $duration; }
    .line-content-3 .underline::after { transition-delay: .25s + (.05s * 2) + $duration; }
}
