.hero {
  height: 100vh;
  height: 100svh;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .webp &    { background-image: url("../assets/images/pages/home/hero/bg-small.jpg?as=webp"); }
  .no-webp & { background-image: url("../assets/images/pages/home/hero/bg-small.jpg"); }

  @include min($tablet) {

    .webp &    { background-image: url("../assets/images/pages/home/hero/bg-large.jpg?as=webp"); }
    .no-webp & { background-image: url("../assets/images/pages/home/hero/bg-large.jpg"); }
  }
}

.hero-container {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.hero-heading {
  margin-bottom: 25px;

  font-size: 2.8rem;
  @include letter-spacing(-15);
  line-height: 1.3;

  @include min($desktop) {
    margin-bottom: 30px;

    font-size: 5.8rem;
    line-height: 1.15;
  }
}

.hero-button {
  transition-property: none;

  display: flex;
  align-items: center;

  cursor: pointer;

  @include min($cinema) {
    transition-property: opacity, transform;
    transition-timing-function: ease-out;
    transition-delay: .25s + (.05s * 4); // same as .line-content-5
  }
}

.hero-button-icon {
  animation: arrow 1s linear infinite;

  width: 18px;
  margin-right: 15px;

  @include min($desktop) {
    width: 24px;
    margin-right: 20px;
  }
}

.hero-button-text {
  font-family: $serif;
  font-weight: 300;
  font-size: 1.6rem;
  @include letter-spacing(-15);
  line-height: 1;

  @include min($desktop) {
    font-size: 2rem;
  }
}

.hero-disclaimer {
  position: absolute;
  bottom: 30px;
  left: 0;

  font-size: 1.1rem;
  line-height: 1.9;

  @include min($desktop) {
    bottom: 35px;

    font-size: 1.3rem;
    line-height: 1.85;
  }

  @include min($cinema) {
    animation-name: heroDisclaimer;
    animation-duration: $duration;
    animation-timing-function: ease-out;
    animation-delay: .25s + (.05s * 5); // same as .line-content-6
    animation-fill-mode: both;
  }
}

.hero-disclaimer-link {
  text-decoration-color: $green;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 1px;
  text-underline-position: under;

  cursor: pointer;
}
