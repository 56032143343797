:where(:not(svg, svg *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

@font-face {
  font-family: Freight;
  font-weight: 300;
  src: url("freight-300.7c89c427.ttf") format("truetype");
}

@font-face {
  font-family: Indivisible;
  font-weight: 400;
  src: url("indivisible-400.ebc1eadf.ttf") format("truetype");
}

@font-face {
  font-family: Indivisible;
  font-weight: 500;
  src: url("indivisible-500.74823471.ttf") format("truetype");
}

html {
  font-size: 10px;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: Indivisible, sans-serif;
  font-weight: 400;
}

.container {
  width: 85%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.loader {
  z-index: 3;
}

.nav {
  z-index: 2;
}

.menu {
  z-index: 1;
}

.dashes {
  height: 55px;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  .dashes {
    height: 100px;
  }
}

.dashes-vector {
  max-width: none;
  width: 770px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 1024px) {
  .dashes-vector {
    width: 1400px;
  }
}

.dashes-path {
  stroke-dasharray: 118;
  stroke-dashoffset: 118px;
  transition: stroke-dashoffset .3s ease-in-out;
}

.dashes-path-1 {
  transition-delay: 0s;
}

.dashes-path-2 {
  transition-delay: 31.25ms;
}

.dashes-path-3 {
  transition-delay: 62.5ms;
}

.dashes-path-4 {
  transition-delay: 93.75ms;
}

.dashes-path-5 {
  transition-delay: .125s;
}

.dashes-path-6 {
  transition-delay: .15625s;
}

.dashes-path-7 {
  transition-delay: .1875s;
}

.dashes-path-8 {
  transition-delay: .21875s;
}

.dashes-path-9 {
  transition-delay: .25s;
}

.dashes-path-10 {
  transition-delay: .28125s;
}

.dashes-path-11 {
  transition-delay: .3125s;
}

.dashes-path-12 {
  transition-delay: .34375s;
}

.dashes-path-13 {
  transition-delay: .375s;
}

.dashes-path-14 {
  transition-delay: .40625s;
}

.dashes-path-15 {
  transition-delay: .4375s;
}

.dashes-path-16 {
  transition-delay: .46875s;
}

.dashes-path-17 {
  transition-delay: .5s;
}

.dashes-path-18 {
  transition-delay: .53125s;
}

.dashes-path-19 {
  transition-delay: .5625s;
}

.dashes-path-20 {
  transition-delay: .59375s;
}

.dashes-path-21 {
  transition-delay: .625s;
}

.dashes-path-22 {
  transition-delay: .65625s;
}

.dashes-path-23 {
  transition-delay: .6875s;
}

.dashes-path-24 {
  transition-delay: .71875s;
}

.dashes-path-25 {
  transition-delay: .75s;
}

.dashes-path-26 {
  transition-delay: .78125s;
}

.dashes-path-27 {
  transition-delay: .8125s;
}

.dashes-path-28 {
  transition-delay: .84375s;
}

.dashes-path-29 {
  transition-delay: .875s;
}

.dashes-path-30 {
  transition-delay: .90625s;
}

.dashes-vector.is-inview .dashes-path {
  stroke-dashoffset: 236px;
}

.footer {
  padding-top: 80px;
  padding-bottom: 60px;
}

@media screen and (min-width: 1024px) {
  .footer {
    padding-top: 110px;
    padding-bottom: 65px;
  }
}

@media screen and (min-width: 1366px) {
  .footer {
    position: relative;
  }
}

.footer-disclaimer {
  margin-bottom: 35px;
  font-size: 1.1rem;
  line-height: 1.9;
}

@media screen and (min-width: 1024px) {
  .footer-disclaimer {
    font-size: 1.3rem;
    line-height: 1.85;
  }
}

@media screen and (min-width: 1366px) {
  .footer-disclaimer {
    margin-bottom: 0;
  }
}

.footer-link {
  text-underline-position: under;
  cursor: pointer;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  text-decoration-thickness: 1px;
}

.footer-link-light {
  -webkit-text-decoration-color: #64e27c;
  text-decoration-color: #64e27c;
}

.footer-link-dark {
  -webkit-text-decoration-color: #0c1923;
  text-decoration-color: #0c1923;
}

.footer-logo {
  width: 59px;
  height: 15px;
  text-indent: 101%;
  white-space: nowrap;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  overflow: hidden;
}

@media screen and (min-width: 1366px) {
  .footer-logo {
    position: absolute;
    top: 115px;
    left: 35px;
  }
}

.footer-logo-light {
  background-image: url("logo-light.c7381b88.svg");
}

.footer-logo-dark {
  background-image: url("logo-dark.7e97bf1f.svg");
}

.inview {
  opacity: 0;
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
}

.inview-up {
  transform: translate3d(0, 4vh, 0);
}

.inview-down {
  transform: translate3d(0, -4vh, 0);
}

.inview-right {
  transform: translate3d(-4vh, 0, 0);
}

.inview-left {
  transform: translate3d(4vh, 0, 0);
}

.inview.is-inview {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@keyframes arrow {
  0%, 50%, 100% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0, -2px, 0);
  }

  75% {
    transform: translate3d(0, 2px, 0);
  }
}

@keyframes heroDisclaimer {
  0% {
    opacity: 0;
    transform: translate3d(0, 4vh, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1366px) {
  .line-mask {
    vertical-align: bottom;
    display: inline-block;
    overflow: hidden;
  }

  .line-content {
    opacity: 0;
    transition-property: opacity, transform;
    transition-duration: .3s;
    transition-timing-function: ease-out;
    display: inline-block;
    transform: translate3d(0, 100%, 0);
  }

  .is-inview .line-content {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .line-content-1 {
    transition-delay: .25s;
  }

  .line-content-2 {
    transition-delay: .3s;
  }

  .line-content-3 {
    transition-delay: .35s;
  }

  .line-content-4 {
    transition-delay: .4s;
  }

  .line-content-5 {
    transition-delay: .45s;
  }
}

.loader {
  visibility: hidden;
  width: 100%;
  height: 100%;
  transition-property: visibility;
  transition-duration: 0s;
  transition-timing-function: linear;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.loader.is-open {
  visibility: visible;
  transition-delay: 0s;
}

.loader.is-closed {
  visibility: hidden;
  transition-delay: .5s;
}

.loader-bar {
  width: calc(20% + 1px);
  height: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  background-color: #0c1923;
  transition: -webkit-clip-path .3s ease-in-out, clip-path .3s ease-in-out;
  position: absolute;
  top: 0;
}

.loader.is-open .loader-bar {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.loader.is-closed .loader-bar {
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
}

.loader.is-open .loader-bar-1 {
  transition-delay: 0s;
}

.loader.is-open .loader-bar-2 {
  transition-delay: 50ms;
}

.loader.is-open .loader-bar-3 {
  transition-delay: .1s;
}

.loader.is-open .loader-bar-4 {
  transition-delay: .15s;
}

.loader.is-open .loader-bar-5, .loader.is-closed .loader-bar-1 {
  transition-delay: .2s;
}

.loader.is-closed .loader-bar-2 {
  transition-delay: .15s;
}

.loader.is-closed .loader-bar-3 {
  transition-delay: .1s;
}

.loader.is-closed .loader-bar-4 {
  transition-delay: 50ms;
}

.loader.is-closed .loader-bar-5 {
  transition-delay: 0s;
}

.loader-bar-1 {
  left: 0;
}

.loader-bar-2 {
  left: 20%;
}

.loader-bar-3 {
  left: 40%;
}

.loader-bar-4 {
  left: 60%;
}

.loader-bar-5 {
  left: 80%;
}

.loader-bar-3 {
  background-image: url("icon.ca1b281e.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.menu {
  width: 100%;
  color: #d5d8e0;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1365px) {
  .menu {
    opacity: 0;
    visibility: hidden;
    height: 100%;
    background-color: #0c1923;
    padding-top: 100px;
    padding-bottom: 35px;
    transition: opacity .5s, visibility 0s linear .5s;
  }
}

@media screen and (min-width: 1366px) {
  .menu {
    width: 150px;
    position: fixed;
    top: 15px;
    left: auto;
    right: 0;
    overflow: hidden;
  }
}

.menu.is-active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s;
}

@media screen and (min-width: 1366px) {
  .menu-dark {
    color: #0c1923;
  }
}

.menu-container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 1366px) {
  .menu-container {
    width: auto;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}

.menu-links {
  flex-direction: column;
  margin-bottom: auto;
  display: flex;
}

.menu-link {
  cursor: pointer;
  margin-bottom: 35px;
  padding-bottom: 10px;
  font-size: 2.1rem;
  line-height: 1;
  position: relative;
}

@media screen and (min-width: 1366px) {
  .menu-link {
    margin-bottom: 0;
    padding-top: 20px;
    font-size: 1.3rem;
    transition: transform .3s ease-in-out;
    transform: translate3d(35px, 0, 0);
  }

  .menu-link:hover {
    transform: translate3d(17.5px, 0, 0);
  }
}

.menu-link.is-active:before {
  opacity: 0;
}

.menu-link.is-active:after {
  opacity: 1;
}

@media screen and (min-width: 1366px) {
  .menu-link.is-active {
    font-weight: 500;
    transform: translate3d(0, 0, 0);
  }
}

.menu-link:last-child {
  margin-bottom: 0;
}

.menu-link:before, .menu-link:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.menu-link:before {
  background-image: linear-gradient(-90deg, #0c1923 0%, #868d9e 100%);
}

.menu-link:after {
  opacity: 0;
  background-color: #64e27c;
}

@media screen and (min-width: 1366px) {
  .menu-dark .menu-link:before {
    background-image: linear-gradient(-90deg, #868d9e 0%, #0c1923 100%);
  }

  .menu-dark .menu-link:after {
    background-color: #0c1923;
  }
}

.menu-disclaimer {
  font-size: 1.1rem;
  line-height: 1.9;
}

@media screen and (min-width: 1366px) {
  .menu-disclaimer {
    display: none;
  }
}

.menu-disclaimer-link {
  text-underline-position: under;
  cursor: pointer;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  text-decoration-thickness: 1px;
}

.menu-disclaimer-link-light {
  -webkit-text-decoration-color: #64e27c;
  text-decoration-color: #64e27c;
}

.menu-disclaimer-link-dark {
  -webkit-text-decoration-color: #0c1923;
  text-decoration-color: #0c1923;
}

.nav {
  width: 100%;
  padding-top: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 1024px) {
  .nav {
    padding-top: 35px;
  }
}

@media screen and (min-width: 1366px) {
  .nav {
    width: auto;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.nav-container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1366px) {
  .nav-container {
    max-width: none;
    width: auto;
    padding-left: 35px;
  }
}

.nav-logo {
  width: 59px;
  height: 15px;
  text-indent: 101%;
  white-space: nowrap;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  overflow: hidden;
}

.nav-dark .nav-logo {
  background-image: url("logo-dark.7e97bf1f.svg");
}

.nav-light .nav-logo {
  background-image: url("logo-light.c7381b88.svg");
}

.nav-switch {
  width: 28px;
  height: 16px;
  cursor: pointer;
  transition: transform .3s ease-in-out;
  position: relative;
}

@media screen and (min-width: 1366px) {
  .nav-switch {
    display: none;
  }
}

.nav-switch.is-active {
  transform: translate3d(7px, 0, 0);
}

.nav-switch-line {
  width: 100%;
  height: 1px;
  transition: transform .3s ease-in-out;
  position: absolute;
  left: 0;
}

.nav-switch.is-active .nav-switch-line-top {
  transform: rotate(45deg)translate3d(0, 5px, 0);
}

.nav-switch.is-active .nav-switch-line-bot {
  transform: rotate(-225deg)translate3d(0, 5px, 0);
}

.nav-dark .nav-switch-line {
  background-color: #0c1923;
}

.nav-light .nav-switch-line {
  background-color: #d5d8e0;
}

.nav-switch-line-top {
  top: 4px;
}

.nav-switch-line-bot {
  bottom: 4px;
}

.next-container {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.next-lockup {
  cursor: pointer;
  align-items: center;
  margin-bottom: 60px;
  display: flex;
}

.next-lockup-text {
  letter-spacing: .05em;
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height: 1;
}

@media screen and (min-width: 1024px) {
  .next-lockup-text {
    font-size: 1.2rem;
  }
}

.next-lockup-separator {
  margin-left: 22.5px;
  margin-right: 22.5px;
}

@media screen and (min-width: 1024px) {
  .next-lockup-separator {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.next-heading {
  letter-spacing: -.015em;
  font-size: 2.8rem;
  line-height: 1.3;
}

@media screen and (min-width: 1024px) {
  .next-heading {
    letter-spacing: -.025em;
    font-size: 5.4rem;
    line-height: 1.15;
  }
}

.next-heading-link {
  cursor: pointer;
}

.rings {
  height: 345px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 60px;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  .rings {
    height: 490px;
    margin-bottom: 95px;
  }
}

.rings-dark {
  background-image: url("bg-dark.8f938594.svg");
}

.rings-light {
  background-image: url("bg-light.ca1b281e.svg");
}

.contact .rings {
  margin-bottom: 0;
}

.ring {
  animation-name: rotate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

.ring-1 {
  animation-duration: 32s;
}

.ring-2 {
  animation-duration: 34s;
}

.ring-3 {
  animation-duration: 36s;
}

.ring-4 {
  animation-duration: 38s;
}

.ring-5 {
  animation-duration: 40s;
}

.ring-6 {
  animation-duration: 42s;
}

.ring-7 {
  animation-duration: 44s;
}

.ring-8 {
  animation-duration: 46s;
}

.ring-9 {
  animation-duration: 48s;
}

.ring-10 {
  animation-duration: 50s;
}

.ring-11 {
  animation-duration: 52s;
}

.ring-12 {
  animation-duration: 54s;
}

.ring-13 {
  animation-duration: 56s;
}

.ring-14 {
  animation-duration: 58s;
}

.ring-15 {
  animation-duration: 60s;
}

.bar {
  width: 2px;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 50%;
}

.dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.rings-dark .dot {
  background-color: #0f1a24;
}

.rings-light .dot {
  background-color: #d6d8e1;
}

.underline {
  text-underline-position: under;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  text-decoration-thickness: 1px;
}

.underline-light {
  -webkit-text-decoration-color: #64e27c;
  text-decoration-color: #64e27c;
}

.underline-dark {
  -webkit-text-decoration-color: #0c1923;
  text-decoration-color: #0c1923;
}

@media screen and (min-width: 1366px) {
  .underline {
    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  .is-inview .underline:after {
    transform: scale3d(1, 1, 1);
  }

  .underline:after {
    content: "";
    transform-origin: 0;
    width: 100%;
    height: 1px;
    transition: transform .3s ease-in-out .3s;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scale3d(0, 1, 1);
  }

  .underline-light:after {
    background-color: #64e27c;
  }

  .underline-dark:after {
    background-color: #0c1923;
  }

  .line-content-2 .underline:after {
    transition-delay: .6s;
  }

  .line-content-3 .underline:after {
    transition-delay: .65s;
  }
}

.about {
  color: #d5d8e0;
  background-color: #0c1923;
}

.about .hero {
  height: 100vh;
  height: 100svh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.webp .about .hero {
  background-image: url("bg-small.ca3749da.webp");
}

.no-webp .about .hero {
  background-image: url("bg-small.682579bc.jpeg");
}

@media screen and (min-width: 768px) {
  .webp .about .hero {
    background-image: url("bg-large.acb39537.webp");
  }

  .no-webp .about .hero {
    background-image: url("bg-large.520381a5.jpeg");
  }
}

.about .hero-container {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.about .hero-heading {
  letter-spacing: -.015em;
  margin-bottom: 25px;
  font-size: 2.8rem;
  line-height: 1.3;
}

@media screen and (min-width: 1024px) {
  .about .hero-heading {
    margin-bottom: 30px;
    font-size: 5.8rem;
    line-height: 1.15;
  }
}

.about .hero-button {
  cursor: pointer;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1366px) {
  .about .hero-button {
    transition-delay: .4s;
    transition-timing-function: ease-out;
  }
}

.about .hero-button-icon {
  width: 18px;
  margin-right: 15px;
  animation: 1s linear infinite arrow;
}

@media screen and (min-width: 1024px) {
  .about .hero-button-icon {
    width: 24px;
    margin-right: 20px;
  }
}

.about .hero-button-text {
  letter-spacing: -.015em;
  font-family: Freight, serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1;
}

@media screen and (min-width: 1024px) {
  .about .hero-button-text {
    font-size: 2rem;
  }
}

.about .relations {
  padding-top: 50px;
}

@media screen and (min-width: 1024px) {
  .about .relations {
    padding-top: 110px;
  }
}

.about .relations-container {
  margin-bottom: 50px;
}

@media screen and (min-width: 1024px) {
  .about .relations-container {
    margin-bottom: 65px;
  }
}

.about .relations-brow {
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-bottom: 35px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
}

@media screen and (min-width: 1024px) {
  .about .relations-brow {
    margin-bottom: 60px;
    font-size: 2.1rem;
    line-height: 1.4;
  }
}

.about .relations-heading {
  letter-spacing: -.015em;
  font-size: 2.2rem;
  line-height: 1.35;
}

@media screen and (min-width: 1024px) {
  .about .relations-heading {
    letter-spacing: -.025em;
    font-size: 3.9rem;
    line-height: 1.2;
  }
}

.about .speed {
  padding-top: 50px;
  padding-bottom: 55px;
}

@media screen and (min-width: 1024px) {
  .about .speed {
    padding-top: 110px;
    padding-bottom: 90px;
  }
}

.about .speed-brow {
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-bottom: 35px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
}

@media screen and (min-width: 1024px) {
  .about .speed-brow {
    margin-bottom: 60px;
    font-size: 2.1rem;
    line-height: 1.4;
  }
}

.about .speed-heading {
  letter-spacing: -.015em;
  font-size: 2.2rem;
  line-height: 1.35;
}

@media screen and (min-width: 1024px) {
  .about .speed-heading {
    letter-spacing: -.025em;
    font-size: 3.9rem;
    line-height: 1.2;
  }
}

.buyers-club {
  color: #d5d8e0;
  background-color: #0c1923;
}

.buyers-club .application {
  margin-bottom: 55px;
}

@media screen and (min-width: 1024px) {
  .buyers-club .application {
    margin-bottom: 100px;
  }
}

.buyers-club .application-heading {
  letter-spacing: -.015em;
  font-size: 2.2rem;
  line-height: 1.35;
}

@media screen and (min-width: 1024px) {
  .buyers-club .application-heading {
    letter-spacing: -.025em;
    font-size: 3.9rem;
    line-height: 1.2;
  }
}

.buyers-club .application-heading-link {
  cursor: pointer;
}

.buyers-club .hero {
  margin-bottom: 90px;
}

@media screen and (min-width: 1024px) {
  .buyers-club .hero {
    margin-bottom: 100px;
  }
}

.buyers-club .hero-container {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.buyers-club .hero-heading {
  letter-spacing: -.015em;
  font-size: 2.8rem;
  line-height: 1.3;
}

@media screen and (min-width: 1024px) {
  .buyers-club .hero-heading {
    font-size: 5.8rem;
    line-height: 1.15;
  }
}

.buyers-club .membership {
  margin-bottom: 60px;
}

@media screen and (min-width: 1024px) {
  .buyers-club .membership {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 768px) {
  .buyers-club .membership-container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5%;
    display: grid;
  }
}

.buyers-club .membership-heading {
  letter-spacing: -.015em;
  margin-bottom: 50px;
  font-size: 2.2rem;
  line-height: 1.35;
}

@media screen and (min-width: 768px) {
  .buyers-club .membership-heading {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .buyers-club .membership-heading {
    letter-spacing: -.025em;
    font-size: 3.9rem;
    line-height: 1.2;
  }
}

.buyers-club .membership-item {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .buyers-club .membership-item {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.buyers-club .membership-item:before {
  content: "";
  transform-origin: 0;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(-90deg, #0c1923 0%, #868d9e 100%);
  transition: transform .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale3d(0, 1, 1);
}

.buyers-club .membership-item.is-inview:before {
  transform: scale3d(1, 1, 1);
}

.buyers-club .membership-item:last-child {
  padding-bottom: 0;
}

.buyers-club .membership-icon {
  margin-bottom: 25px;
}

.buyers-club .membership-range {
  letter-spacing: -.015em;
  font-size: 1.8rem;
  line-height: 1.5;
}

@media screen and (min-width: 1024px) {
  .buyers-club .membership-range {
    letter-spacing: -.025em;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.05;
  }
}

.careers {
  color: #0c1923;
  background-color: #9bb7c6;
}

.careers .hero {
  margin-bottom: 75px;
}

@media screen and (min-width: 1024px) {
  .careers .hero {
    margin-bottom: 125px;
  }
}

.careers .hero-heading {
  letter-spacing: -.015em;
  margin-bottom: 30px;
  font-size: 4.8rem;
  line-height: 1;
}

@media screen and (min-width: 1024px) {
  .careers .hero-heading {
    margin-bottom: 50px;
    font-size: 9.2rem;
    line-height: .815;
  }
}

.careers .hero-subhead {
  letter-spacing: -.015em;
  font-size: 2.8rem;
  line-height: 1.3;
}

@media screen and (min-width: 1024px) {
  .careers .hero-subhead {
    letter-spacing: -.025em;
    font-size: 3.9rem;
    line-height: 1.2;
  }
}

.careers .hero-subhead-link {
  cursor: pointer;
}

.contact {
  color: #0c1923;
  background-color: #9bb7c6;
}

.contact .form {
  margin-bottom: 55px;
}

@media screen and (min-width: 1024px) {
  .contact .form {
    margin-bottom: 80px;
  }
}

.contact .form-brow {
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-bottom: 65px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
}

@media screen and (min-width: 1024px) {
  .contact .form-brow {
    margin-bottom: 55px;
    font-size: 2.1rem;
    line-height: 1.4;
  }
}

.contact .form-field {
  margin-bottom: 50px;
}

@media screen and (min-width: 1024px) {
  .contact .form-field {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 768px) {
  .contact .form-group {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5%;
    display: grid;
  }
}

.contact .form-label {
  letter-spacing: -.015em;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.65;
  display: block;
}

@media screen and (min-width: 1024px) {
  .contact .form-label {
    margin-bottom: 20px;
    font-size: 1.8rem;
    line-height: 1.4;
  }
}

.contact .form-input, .contact .form-textarea {
  width: 100%;
  letter-spacing: -.015em;
  font-family: Freight, serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1;
  display: block;
}

@media screen and (min-width: 1024px) {
  .contact .form-input, .contact .form-textarea {
    font-size: 2.3rem;
  }
}

.contact .form-input::placeholder, .contact .form-textarea::placeholder {
  opacity: .6;
  color: #0c1923;
}

.contact .form-input {
  border-bottom: 1px solid #0c1923;
  padding-bottom: 10px;
}

@media screen and (min-width: 1024px) {
  .contact .form-input {
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .contact .form-group .form-input:first-child {
    margin-bottom: 25px;
  }
}

.contact .form-textarea {
  height: 320px;
  border: 1px solid #0c1923;
  padding: 20px 17.5px;
}

@media screen and (min-width: 768px) {
  .contact .form-textarea {
    height: 165px;
  }
}

.contact .form-submit {
  width: 130px;
  height: 50px;
  letter-spacing: .05em;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid #0c1923;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .contact .form-submit {
    width: 160px;
    height: 64px;
    border-radius: 32px;
    font-size: 1.8rem;
  }
}

.contact .hero {
  margin-bottom: 60px;
}

@media screen and (min-width: 1024px) {
  .contact .hero {
    margin-bottom: 100px;
  }
}

.contact .hero-heading {
  position: relative;
}

.contact .hero-heading-spacer {
  height: 0;
  padding-top: 14.4503%;
}

.contact .hero-heading-replace {
  width: 100%;
  height: 100%;
  text-indent: 101%;
  white-space: nowrap;
  background-image: url("heading.cc42717c.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.home {
  color: #d5d8e0;
  background-color: #0c1923;
}

.home .hero {
  height: 100vh;
  height: 100svh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.webp .home .hero {
  background-image: url("bg-small.ce725ebc.webp");
}

.no-webp .home .hero {
  background-image: url("bg-small.8ad0e71d.jpeg");
}

@media screen and (min-width: 768px) {
  .webp .home .hero {
    background-image: url("bg-large.72341449.webp");
  }

  .no-webp .home .hero {
    background-image: url("bg-large.d3495f52.jpeg");
  }
}

.home .hero-container {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.home .hero-heading {
  letter-spacing: -.015em;
  margin-bottom: 25px;
  font-size: 2.8rem;
  line-height: 1.3;
}

@media screen and (min-width: 1024px) {
  .home .hero-heading {
    margin-bottom: 30px;
    font-size: 5.8rem;
    line-height: 1.15;
  }
}

.home .hero-button {
  cursor: pointer;
  align-items: center;
  transition-property: none;
  display: flex;
}

@media screen and (min-width: 1366px) {
  .home .hero-button {
    transition-property: opacity, transform;
    transition-delay: .45s;
    transition-timing-function: ease-out;
  }
}

.home .hero-button-icon {
  width: 18px;
  margin-right: 15px;
  animation: 1s linear infinite arrow;
}

@media screen and (min-width: 1024px) {
  .home .hero-button-icon {
    width: 24px;
    margin-right: 20px;
  }
}

.home .hero-button-text {
  letter-spacing: -.015em;
  font-family: Freight, serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1;
}

@media screen and (min-width: 1024px) {
  .home .hero-button-text {
    font-size: 2rem;
  }
}

.home .hero-disclaimer {
  font-size: 1.1rem;
  line-height: 1.9;
  position: absolute;
  bottom: 30px;
  left: 0;
}

@media screen and (min-width: 1024px) {
  .home .hero-disclaimer {
    font-size: 1.3rem;
    line-height: 1.85;
    bottom: 35px;
  }
}

@media screen and (min-width: 1366px) {
  .home .hero-disclaimer {
    animation-name: heroDisclaimer;
    animation-duration: .3s;
    animation-timing-function: ease-out;
    animation-delay: .5s;
    animation-fill-mode: both;
  }
}

.home .hero-disclaimer-link {
  text-underline-position: under;
  cursor: pointer;
  -webkit-text-decoration: underline #64e27c;
  text-decoration: underline #64e27c;
  text-decoration-thickness: 1px;
}

.home .industry {
  padding-top: 55px;
  padding-bottom: 55px;
}

@media screen and (min-width: 1024px) {
  .home .industry {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

@media screen and (min-width: 768px) {
  .home .industry-container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5%;
    display: grid;
  }
}

.home .industry-heading {
  letter-spacing: -.015em;
  margin-bottom: 45px;
  font-size: 2.2rem;
  line-height: 1.35;
}

@media screen and (min-width: 768px) {
  .home .industry-heading {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .home .industry-heading {
    letter-spacing: -.025em;
    font-size: 3.4rem;
    line-height: 1.15;
  }
}

.home .industry-item {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .home .industry-item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.home .industry-item:before {
  content: "";
  transform-origin: 0;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(-90deg, #0c1923 0%, #868d9e 100%);
  transition: transform .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale3d(0, 1, 1);
}

.home .industry-item.is-inview:before {
  transform: scale3d(1, 1, 1);
}

.home .industry-item:last-child {
  padding-bottom: 0;
}

.home .industry-item-content {
  opacity: 0;
  align-items: center;
  transition: opacity .3s ease-in-out;
  display: flex;
}

.home .industry-item.is-inview .industry-item-content {
  opacity: 1;
}

.home .industry-item-bullet {
  width: 11px;
  margin-right: 10px;
}

@media screen and (min-width: 1024px) {
  .home .industry-item-bullet {
    width: 14px;
    margin-right: 15px;
  }
}

.home .industry-item-text {
  letter-spacing: -.015em;
  font-family: Freight, serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1;
}

@media screen and (min-width: 1024px) {
  .home .industry-item-text {
    font-size: 2rem;
  }
}

.home .raise {
  color: #0c1923;
  background-color: #91adbc;
  padding-top: 50px;
  padding-bottom: 55px;
}

@media screen and (min-width: 1024px) {
  .home .raise {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

.home .raise-brow {
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-bottom: 35px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
}

@media screen and (min-width: 1024px) {
  .home .raise-brow {
    margin-bottom: 60px;
    font-size: 2.1rem;
    line-height: 1.4;
  }
}

.home .raise-heading {
  letter-spacing: -.015em;
  font-size: 2.2rem;
  line-height: 1.35;
}

@media screen and (min-width: 1024px) {
  .home .raise-heading {
    letter-spacing: -.025em;
    font-size: 3.9rem;
    line-height: 1.2;
  }
}

.home .raise-heading-1 {
  margin-bottom: 30px;
}

@media screen and (min-width: 1024px) {
  .home .raise-heading-1 {
    margin-bottom: 50px;
  }
}

.home .source {
  padding-top: 50px;
}

@media screen and (min-width: 1024px) {
  .home .source {
    padding-top: 125px;
  }
}

.home .source-container {
  margin-bottom: 50px;
}

@media screen and (min-width: 1024px) {
  .home .source-container {
    margin-bottom: 125px;
  }
}

.home .source-brow {
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
}

@media screen and (min-width: 1024px) {
  .home .source-brow {
    margin-bottom: 30px;
    font-size: 2.1rem;
    line-height: 1.425;
  }
}

.home .source-heading {
  letter-spacing: -.015em;
  margin-bottom: 30px;
  font-size: 2.2rem;
  line-height: 1.35;
}

@media screen and (min-width: 1024px) {
  .home .source-heading {
    letter-spacing: -.025em;
    margin-bottom: 50px;
    font-size: 3.4rem;
    line-height: 1.15;
  }
}

@media screen and (min-width: 768px) {
  .home .source-list {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5%;
    display: grid;
  }
}

.home .source-item {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .home .source-item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.home .source-item:before {
  content: "";
  transform-origin: 0;
  width: 100%;
  height: 1px;
  background-color: #d5d8e0;
  transition: transform .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale3d(0, 1, 1);
}

.home .source-item.is-inview:before {
  transform: scale3d(1, 1, 1);
}

@media screen and (max-width: 767px) {
  .home .source-item:last-child {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .home .source-item-1, .home .source-item-2, .home .source-item-3 {
    grid-column: 1;
  }

  .home .source-item-4, .home .source-item-5, .home .source-item-6 {
    grid-column: 2;
  }

  .home .source-item-1, .home .source-item-4 {
    grid-row: 1;
  }

  .home .source-item-2, .home .source-item-5 {
    grid-row: 2;
  }

  .home .source-item-3, .home .source-item-6 {
    grid-row: 3;
    padding-bottom: 0;
  }
}

.home .source-item-content {
  opacity: 0;
  align-items: center;
  transition: opacity .3s ease-in-out;
  display: flex;
}

.home .source-item.is-inview .source-item-content {
  opacity: 1;
}

.home .source-item-bullet {
  width: 11px;
  margin-right: 10px;
}

@media screen and (min-width: 1024px) {
  .home .source-item-bullet {
    width: 14px;
    margin-right: 15px;
  }
}

.home .source-item-text {
  letter-spacing: -.015em;
  font-family: Freight, serif;
  font-size: 1.6rem;
  font-weight: 300;
}

@media screen and (min-width: 1024px) {
  .home .source-item-text {
    font-size: 2.3rem;
  }
}

.leadership {
  color: #0c1923;
  background-color: #9bb7c6;
}

.leadership .hero {
  margin-bottom: 65px;
}

.leadership .hero-heading {
  letter-spacing: -.015em;
  margin-bottom: 25px;
  font-size: 2.8rem;
  line-height: 1.3;
}

@media screen and (min-width: 1024px) {
  .leadership .hero-heading {
    margin-bottom: 100px;
    font-size: 5.8rem;
    line-height: 1.15;
  }
}

.leadership .partner {
  margin-bottom: 55px;
}

@media screen and (min-width: 1024px) {
  .leadership .partner {
    margin-bottom: 105px;
  }
}

.leadership .partner-brow {
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-bottom: 25px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
}

@media screen and (min-width: 1024px) {
  .leadership .partner-brow {
    margin-bottom: 50px;
    font-size: 2.1rem;
    line-height: 1.4;
  }
}

.leadership .partner-lockup {
  margin-bottom: 35px;
}

@media screen and (min-width: 768px) {
  .leadership .partner-lockup {
    align-items: center;
    display: flex;
  }
}

.leadership .partner-lockup-image {
  width: 100px;
  height: 100px;
  background-image: url("image.529cd019.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .leadership .partner-lockup-image {
    margin-bottom: 0;
    margin-right: 25px;
  }
}

@media screen and (min-width: 1024px) {
  .leadership .partner-lockup-image {
    width: 130px;
    height: 130px;
    margin-right: 30px;
  }
}

.leadership .partner-lockup-copy {
  letter-spacing: -.015em;
  font-size: 2.1rem;
  font-weight: 500;
  line-height: 1.2;
}

@media screen and (min-width: 1024px) {
  .leadership .partner-lockup-copy {
    font-size: 2.6rem;
  }
}

.leadership .partner-bio {
  letter-spacing: -.015em;
  font-size: 2.2rem;
  line-height: 1.35;
}

@media screen and (min-width: 1024px) {
  .leadership .partner-bio {
    font-size: 3.2rem;
  }
}

.transactions {
  color: #0c1923;
  background-color: #9bb7c6;
}

.transactions .example {
  margin-bottom: 45px;
}

@media screen and (min-width: 1024px) {
  .transactions .example {
    margin-bottom: 80px;
  }
}

.transactions .example-container {
  padding-bottom: 45px;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .transactions .example-container {
    padding-bottom: 80px;
  }
}

.transactions .example-last .example-container {
  padding-bottom: 0;
}

.transactions .example-brow {
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 500;
}

@media screen and (min-width: 1024px) {
  .transactions .example-brow {
    margin-bottom: 30px;
    font-size: 2.1rem;
    line-height: 1.4;
  }
}

@media screen and (min-width: 768px) {
  .transactions .example-grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5%;
    display: grid;
  }
}

.transactions .example-heading {
  letter-spacing: -.015em;
  margin-bottom: 40px;
  font-size: 3.2rem;
}

@media screen and (min-width: 768px) {
  .transactions .example-heading {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .transactions .example-heading {
    font-size: 7.6rem;
    line-height: 1;
  }
}

.transactions .example-pair {
  margin-bottom: 35px;
}

.transactions .example-pair:last-child {
  margin-bottom: 0;
}

.transactions .example-pair-lead {
  letter-spacing: .05em;
  margin-bottom: 15px;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
}

.transactions .example-pair-follow {
  letter-spacing: -.015em;
  font-family: Freight, serif;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.3;
}

@media screen and (min-width: 1024px) {
  .transactions .example-pair-follow {
    font-size: 2.2rem;
    line-height: 1.2;
  }
}

.transactions .example-rule {
  transform-origin: 0;
  width: 100%;
  height: 1px;
  background-color: #0c1923;
  transition: transform .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 1, 1);
}

.transactions .example-rule.is-inview {
  transform: scale3d(1, 1, 1);
}

.transactions .hero {
  margin-bottom: 90px;
}

@media screen and (min-width: 1024px) {
  .transactions .hero {
    margin-bottom: 125px;
  }
}

.transactions .hero-heading {
  position: relative;
}

.transactions .hero-heading-spacer {
  height: 0;
  padding-top: 13.3201%;
}

.transactions .hero-heading-replace {
  width: 100%;
  height: 100%;
  text-indent: 101%;
  white-space: nowrap;
  background-image: url("heading.42e6b188.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

/*# sourceMappingURL=index.b49d6579.css.map */
