.speed {
  padding-top: 50px;
  padding-bottom: 55px;

  @include min($desktop) {
    padding-top: 110px;
    padding-bottom: 90px;
  }
}

.speed-container {

}

.speed-brow {
  margin-bottom: 35px;

  font-weight: 500;
  font-size: 1.5rem;
  @include letter-spacing(50);
  line-height: 1.6;
  text-transform: uppercase;

  @include min($desktop) {
    margin-bottom: 60px;

    font-size: 2.1rem;
    line-height: 1.4;
  }
}

.speed-heading {
  font-size: 2.2rem;
  @include letter-spacing(-15);
  line-height: 1.35;

  @include min($desktop) {
    font-size: 3.9rem;
    @include letter-spacing(-25);
    line-height: 1.2;
  }
}
