@use "sass:math";

.hero {
  margin-bottom: 60px;

  @include min($desktop) {
    margin-bottom: 100px;
  }
}

.hero-container {

}

// ratio: 955 x 138
.hero-heading {
  position: relative;
}

.hero-heading-spacer {
  height: 0;
  padding-top: 100% * math.div(138, 955);
}

.hero-heading-replace {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url("../assets/images/pages/contact/hero/heading.svg");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  @include image-replace;
}
