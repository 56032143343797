.application {
  margin-bottom: 55px;

  @include min($desktop) {
    margin-bottom: 100px;
  }
}

.application-container {

}

.application-heading {
  font-size: 2.2rem;
  @include letter-spacing(-15);
  line-height: 1.35;

  @include min($desktop) {
    font-size: 3.9rem;
    @include letter-spacing(-25);
    line-height: 1.2;
  }
}

.application-heading-link {
  cursor: pointer;
}
