html {
  font-size: 10px;      // 1rem = 10px
}

body {
  font-weight: 400;
  font-family: $sans;

  -webkit-font-smoothing: antialiased;
}

.container {
  width: 85%;
  max-width: $container-max-width;
  margin-right: auto;
  margin-left: auto;
}
