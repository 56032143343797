.form {
  margin-bottom: 55px;

  @include min($desktop) {
    margin-bottom: 80px;
  }
}

.form-container {

}

.form-brow {
  margin-bottom: 65px;

  font-weight: 500;
  font-size: 1.5rem;
  @include letter-spacing(50);
  line-height: 1.6;
  text-transform: uppercase;

  @include min($desktop) {
    margin-bottom: 55px;

    font-size: 2.1rem;
    line-height: 1.4;
  }
}

.form-field {
  margin-bottom: 50px;

  @include min($desktop) {
    margin-bottom: 60px;
  }
}

.form-group {

  @include min($tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5%;
  }
}

.form-label {
  display: block;
  margin-bottom: 10px;

  font-weight: 500;
  font-size: 1.5rem;
  @include letter-spacing(-15);
  line-height: 1.65;

  @include min($desktop) {
    margin-bottom: 20px;

    font-size: 1.8rem;
    line-height: 1.4;
  }
}

.form-input,
.form-textarea {
  display: block;
  width: 100%;

  font-family: $serif;
  font-weight: 300;
  font-size: 2rem;
  @include letter-spacing(-15);
  line-height: 1;

  @include min($desktop) {
    font-size: 2.3rem;
  }
}

  // placeholder

  .form-input::placeholder,
  .form-textarea::placeholder {
    opacity: .6;

    color: $blue-dark;
  }

.form-input {
  padding-bottom: 10px;

  border-bottom: 1px solid $blue-dark;

  @include min($desktop) {
    padding-bottom: 15px;
  }
}

  // group

  @include max($tablet) {
    .form-group .form-input:first-child {
      margin-bottom: 25px;
    }
  }

.form-textarea {
  height: 320px;
  padding: 20px 17.5px;

  border: 1px solid $blue-dark;

  @include min($tablet) {
    height: 165px;
  }
}

.form-submit {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 130px;
  height: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: $blue-dark;
  border-radius: 25px;

  font-size: 1.5rem;
  font-weight: 500;
  @include letter-spacing(50);
  text-transform: uppercase;

  cursor: pointer;

  @include min($desktop) {
    width: 160px;
    height: 64px;
    border-radius: 32px;

    font-size: 1.8rem;
  }
}
