.example {
  margin-bottom: 45px;

  @include min($desktop) {
    margin-bottom: 80px;
  }
}

.example-container {
  position: relative;

  padding-bottom: 45px;

  @include min($desktop) {
    padding-bottom: 80px;
  }
}

  // last

  .example-last .example-container {
    padding-bottom: 0;
  }

.example-brow {
  margin-bottom: 10px;

  font-weight: 500;
  font-size: 1.5rem;
  @include letter-spacing(50);
  text-transform: uppercase;

  @include min($desktop) {
    margin-bottom: 30px;

    font-size: 2.1rem;
    line-height: 1.4;
  }
}

.example-grid {

  @include min($tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5%;
  }
}

.example-heading {
  margin-bottom: 40px;

  font-size: 3.2rem;
  @include letter-spacing(-15);

  @include min($tablet) {
    margin-bottom: 0;
  }

  @include min($desktop) {
    font-size: 7.6rem;
    line-height: 1;
  }
}

.example-content {

}

.example-pair {
  margin-bottom: 35px;
}

  // last

  .example-pair:last-child {
    margin-bottom: 0;
  }

.example-pair-lead {
  margin-bottom: 15px;

  font-weight: 500;
  font-size: 1.4rem;
  @include letter-spacing(50);
  line-height: 1;
}

.example-pair-follow {
  font-family: $serif;
  font-weight: 300;
  font-size: 1.8rem;
  @include letter-spacing(-15);
  line-height: 1.3;

  @include min($desktop) {
    font-size: 2.2rem;
    line-height: 1.2;
  }
}

.example-rule {
  transform: scale3d(0, 1, 1);
  transform-origin: left center;
  transition: transform $duration $easing;

  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 1px;

  background-color: $blue-dark;
}

  // inview

  .example-rule.is-inview {
    transform: scale3d(1, 1, 1);
  }
