.relations {
  padding-top: 50px;

  @include min($desktop) {
    padding-top: 110px;
  }
}

.relations-container {
  margin-bottom: 50px;

  @include min($desktop) {
    margin-bottom: 65px;
  }
}

.relations-brow {
  margin-bottom: 35px;

  font-weight: 500;
  font-size: 1.5rem;
  @include letter-spacing(50);
  line-height: 1.6;
  text-transform: uppercase;

  @include min($desktop) {
    margin-bottom: 60px;

    font-size: 2.1rem;
    line-height: 1.4;
  }
}

.relations-heading {
  font-size: 2.2rem;
  @include letter-spacing(-15);
  line-height: 1.35;

  @include min($desktop) {
    font-size: 3.9rem;
    @include letter-spacing(-25);
    line-height: 1.2;
  }
}
