.hero {
  margin-bottom: 75px;

  @include min($desktop) {
    margin-bottom: 125px;
  }
}

.hero-container {

}

.hero-heading {
  margin-bottom: 30px;

  font-size: 4.8rem;
  @include letter-spacing(-15);
  line-height: 1;

  @include min($desktop) {
    margin-bottom: 50px;

    font-size: 9.2rem;
    line-height: .815;
  }
}

.hero-subhead {
  font-size: 2.8rem;
  @include letter-spacing(-15);
  line-height: 1.3;

  @include min($desktop) {
    font-size: 3.9rem;
    @include letter-spacing(-25);
    line-height: 1.2;
  }
}

.hero-subhead-link {
  cursor: pointer;
}
