.home {
  background-color: $blue-dark;

  color: $gray;
}

.home {
  @import "hero";
  @import "industry";
  @import "raise";
  @import "source";
}
