.next {

}

.next-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.next-lockup {
  display: flex;
  align-items: center;
  margin-bottom: 60px;

  cursor: pointer;
}

.next-lockup-text {
  font-size: 1.1rem;
  @include letter-spacing(50);
  line-height: 1;
  text-transform: uppercase;

  @include min($desktop) {
    font-size: 1.2rem;
  }
}

.next-lockup-separator {
  margin-right: 22.5px;
  margin-left: 22.5px;

  @include min($desktop) {
    margin-right: 40px;
    margin-left: 40px;
  }
}

.next-heading {
  font-size: 2.8rem;
  @include letter-spacing(-15);
  line-height: 1.3;

  @include min($desktop) {
    font-size: 5.4rem;
    @include letter-spacing(-25);
    line-height: 1.15;
  }
}

.next-heading-link {
  cursor: pointer;
}
