.source {
  padding-top: 50px;

  @include min($desktop) {
    padding-top: 125px;
  }
}

.source-container {
  margin-bottom: 50px;

  @include min($desktop) {
    margin-bottom: 125px;
  }
}

.source-brow {
  margin-bottom: 20px;

  font-weight: 500;
  font-size: 1.5rem;
  @include letter-spacing(50);
  line-height: 1.6;
  text-transform: uppercase;

  @include min($desktop) {
    margin-bottom: 30px;

    font-size: 2.1rem;
    line-height: 1.425;
  }
}

.source-heading {
  margin-bottom: 30px;

  font-size: 2.2rem;
  @include letter-spacing(-15);
  line-height: 1.35;

  @include min($desktop) {
    margin-bottom: 50px;

    font-size: 3.4rem;
    @include letter-spacing(-25);
    line-height: 1.15;
  }
}

.source-list {

  @include min($tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5%;
  }
}

.source-item {
  position: relative;

  padding-top: 15px;
  padding-bottom: 15px;

  @include min($desktop) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

  //  border

  .source-item::before {
    content: "";

    transform: scale3d(0, 1, 1);
    transform-origin: left center;
    transition: transform $duration $easing;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 1px;

    background-color: $gray;
  }

  .source-item.is-inview::before {
    transform: scale3d(1, 1, 1);
  }

  // last

  @include max($tablet) {
    .source-item:last-child {
      padding-bottom: 0;
    }
  }

  // grid

  @include min($tablet) {
    .source-item-1,
    .source-item-2,
    .source-item-3 {
      grid-column: 1;
    }

    .source-item-4,
    .source-item-5,
    .source-item-6 {
      grid-column: 2;
    }

    .source-item-1,
    .source-item-4 {
      grid-row: 1;
    }

    .source-item-2,
    .source-item-5 {
      grid-row: 2;
    }

    .source-item-3,
    .source-item-6 {
      grid-row: 3;
      padding-bottom: 0;
    }
  }

.source-item-content {
  opacity: 0;

  transition: opacity $duration $easing;

  display: flex;
  align-items: center;
}

  // inview

  .source-item.is-inview .source-item-content {
    opacity: 1;
  }

.source-item-bullet {
  width: 11px;
  margin-right: 10px;

  @include min($desktop) {
    width: 14px;
    margin-right: 15px;
  }
}

.source-item-text {
  font-family: $serif;
  font-weight: 300;
  font-size: 1.6rem;
  @include letter-spacing(-15);

  @include min($desktop) {
    font-size: 2.3rem;
  }
}
