@use "sass:math";

.hero {
  // FIX: need padding-top calculation to center heading vertically
  margin-bottom: 90px;

  @include min($desktop) {
    margin-bottom: 125px;
  }
}

.hero-container {

}

// ratio: 1006 x 134
.hero-heading {
  position: relative;
}

.hero-heading-spacer {
  height: 0;
  padding-top: 100% * math.div(134, 1006);
}

.hero-heading-replace {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url("../assets/images/pages/transactions/hero/heading.svg");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  @include image-replace;
}
