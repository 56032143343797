@use "sass:math";

.dashes {
  overflow: hidden;

  position: relative;

  height: 55px;

  @include min($desktop) {
    height: 100px;
  }
}

// ratio: 1400 x 100
.dashes-vector {
  transform: translateX(-50%);

  position: absolute;
  top: 0;
  left: 50%;

  display: block;
  max-width: none;
  width: 55px * math.div(1400, 100);
  height: 100%;

  @include min($desktop) {
    width: 1400px;
  }
}

.dashes-path {
  transition: stroke-dashoffset $duration $easing;

  stroke-dasharray: 118;
  stroke-dashoffset: 118;
}

  // delay

  @for $index from 1 through 30 {
    .dashes-path-#{ $index } {
      transition-delay: math.div(1s, 32) * ($index - 1);
    }
  }

  // inview

  .dashes-vector.is-inview .dashes-path {
    stroke-dashoffset: 118 * 2;
  }
