@include min($cinema) {
  .line-mask {
    overflow: hidden;

    display: inline-block;

    vertical-align: bottom;
  }

  .line-content {
    opacity: 0;

    transform: translate3d(0, 100%, 0);
    transition-property: opacity, transform;
    transition-duration: $duration;
    transition-timing-function: ease-out;

    display: inline-block;
  }

    // inview

    .is-inview .line-content {
      opacity: 1;

      transform: translate3d(0, 0, 0);
    }

    // delay
    // .loader takes .5s to exit

    .line-content-1 { transition-delay: .25s + (.05s * 0); }
    .line-content-2 { transition-delay: .25s + (.05s * 1); }
    .line-content-3 { transition-delay: .25s + (.05s * 2); }
    .line-content-4 { transition-delay: .25s + (.05s * 3); }
    .line-content-5 { transition-delay: .25s + (.05s * 4); }
}
