.hero {
  // FIX: need padding-top calculation to center heading vertically
  margin-bottom: 90px;

  @include min($desktop) {
    margin-bottom: 100px;
  }
}

.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.hero-heading {
  font-size: 2.8rem;
  @include letter-spacing(-15);
  line-height: 1.3;

  @include min($desktop) {
    font-size: 5.8rem;
    line-height: 1.15;
  }
}
