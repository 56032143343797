.footer {
  padding-top: 80px;
  padding-bottom: 60px;

  @include min($desktop) {
    padding-top: 110px;
    padding-bottom: 65px;
  }

  @include min($cinema) {
    position: relative;
  }
}

.footer-container {

}

.footer-disclaimer {
  margin-bottom: 35px;

  font-size: 1.1rem;
  line-height: 1.9;

  @include min($desktop) {
    font-size: 1.3rem;
    line-height: 1.85;
  }

  @include min($cinema) {
    margin-bottom: 0;
  }
}

.footer-link {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 1px;
  text-underline-position: under;

  cursor: pointer;
}

  // color

  .footer-link-light { text-decoration-color: $green; }
  .footer-link-dark  { text-decoration-color: $blue-dark; }

.footer-logo {
  display: block;
  width: 59px;
  height: 15px;

  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  @include image-replace;
  cursor: pointer;

  @include min($cinema) {
    position: absolute;
    top: 115px; // based on .footer padding-top
    left: 35px;
  }
}

  // bg image

  .footer-logo-light { background-image: url("../assets/images/components/footer/logo-light.svg"); }
  .footer-logo-dark  { background-image: url("../assets/images/components/footer/logo-dark.svg"); }
