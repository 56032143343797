.buyers-club {
  background-color: $blue-dark;

  color: $gray;
}

.buyers-club {
  @import "application";
  @import "hero";
  @import "membership";
}
