.menu {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;

  color: $gray;

  @include max($cinema) {
    opacity: 0;
    visibility: hidden;

    transition-property: opacity, visibility;
    transition-duration: .5s, 0s;
    transition-timing-function: ease, linear;
    transition-delay: 0s, .5s;

    height: 100%;
    padding-top: 100px;
    padding-bottom: 35px;

    background-color: $blue-dark;
  }

  @include min($cinema) {
    overflow: hidden;

    position: fixed;
    top: 15px;
    right: 0;
    left: auto;

    width: 150px;
  }
}

  // active

  .menu.is-active {
    opacity: 1;
    visibility: visible;

    transition-delay: 0s, 0s;
  }

  // dark

  @include min($cinema) {
    .menu-dark {
      color: $blue-dark;
    }
  }

.menu-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include min($cinema) {
    width: auto;
    max-width: none;
    margin-right: 0;
    margin-left: 0;
  }
}

.menu-links {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}

.menu-link {
  position: relative;

  margin-bottom: 35px;
  padding-bottom: 10px;

  font-size: 2.1rem;
  line-height: 1;

  cursor: pointer;

  @include min($cinema) {
    transform: translate3d(35px, 0, 0);
    transition: transform $duration $easing;

    margin-bottom: 0;
    padding-top: 20px;

    font-size: 1.3rem;
  }
}

  // hover

  .menu-link:hover {

    @include min($cinema) {
      transform: translate3d(17.5px, 0, 0);
    }
  }

  // active

  .menu-link.is-active {
    &::before { opacity: 0; }
    &::after  { opacity: 1; }

    @include min($cinema) {
      transform: translate3d(0, 0, 0);

      font-weight: 500;
    }
  }

  // last

  .menu-link:last-child {
    margin-bottom: 0;
  }

  // psuedo

  .menu-link::before,
  .menu-link::after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;
  }

  .menu-link::before {
    background-image: linear-gradient(-90deg, #0C1923 0%, #868D9E 100%);
  }

  .menu-link::after {
    opacity: 0;

    background-color: $green;
  }

  @include min($cinema) {
    .menu-dark .menu-link {
      &::before { background-image: linear-gradient(-90deg, #868D9E 0%, #0C1923 100%); }
      &::after  { background-color: $blue-dark; }
    }
  }

.menu-disclaimer {
  font-size: 1.1rem;
  line-height: 1.9;

  @include min($cinema) {
    display: none;
  }
}

.menu-disclaimer-link {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 1px;
  text-underline-position: under;

  cursor: pointer;
}

  // color

  .menu-disclaimer-link-light { text-decoration-color: $green; }
  .menu-disclaimer-link-dark  { text-decoration-color: $blue-dark; }
