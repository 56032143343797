.contact {
  background-color: #9BB7C6;

  color: $blue-dark;
}

.contact {
  @import "form";
  @import "hero";
}
