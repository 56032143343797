@use "sass:math";

.rings {
  overflow: hidden;

  position: relative;

  height: 345px;
  margin-bottom: 60px;

  background-position: center;
  background-repeat: no-repeat;

  @include min($desktop) {
    height: 490px;
    margin-bottom: 95px;
  }
}

  // bg image

  .rings-dark  { background-image: url("../assets/images/components/rings/bg-dark.svg"); }
  .rings-light { background-image: url("../assets/images/components/rings/bg-light.svg"); }

  // page-specific

  .contact .rings { margin-bottom: 0; }

.ring {
  animation-name: rotate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  position: absolute;
  top: 50%;
  left: 50%;
}

  @for $index from 1 through 15 {
    .ring-#{ $index } {
      animation-duration: 30s + (2s * $index);
    }
  }

.bar {
  position: absolute;
  top: 0;
  left: 50%;

  width: 2px;
  height: 100%;
  margin-left: -1px;
}

.dot {
  position: absolute;
  top: 0;
  left: 0;

  width: 2px;
  height: 2px;
  border-radius: 50%;
}

  // bg color

  .rings-dark .dot  { background-color: #0F1A24; }
  .rings-light .dot { background-color: #D6D8E1; }
