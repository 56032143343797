.industry {
  padding-top: 55px;
  padding-bottom: 55px;

  @include min($desktop) {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

.industry-container {

  @include min($tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5%;
  }
}

.industry-heading {
  margin-bottom: 45px;

  font-size: 2.2rem;
  @include letter-spacing(-15);
  line-height: 1.35;

  @include min($tablet) {
    margin-bottom: 0;
  }

  @include min($desktop) {
    font-size: 3.4rem;
    @include letter-spacing(-25);
    line-height: 1.15;
  }
}

.industry-list {

}

.industry-item {
  position: relative;

  padding-top: 15px;
  padding-bottom: 15px;

  @include min($desktop) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

  // gradient

  .industry-item::before {
    content: "";

    transform: scale3d(0, 1, 1);
    transform-origin: left center;
    transition: transform $duration $easing;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 1px;

    background-image: linear-gradient(-90deg, #0C1923 0%, #868D9E 100%);
  }

  .industry-item.is-inview::before {
    transform: scale3d(1, 1, 1);
  }

  // last

  .industry-item:last-child {
    padding-bottom: 0;
  }

.industry-item-content {
  opacity: 0;

  transition: opacity $duration $easing;

  display: flex;
  align-items: center;
}

  // inview

  .industry-item.is-inview .industry-item-content {
    opacity: 1;
  }

.industry-item-bullet {
  width: 11px;
  margin-right: 10px;

  @include min($desktop) {
    width: 14px;
    margin-right: 15px;
  }
}

.industry-item-text {
  font-family: $serif;
  font-weight: 300;
  font-size: 1.6rem;
  @include letter-spacing(-15);
  line-height: 1;

  @include min($desktop) {
    font-size: 2rem;
  }
}
