.partner {
  margin-bottom: 55px;

  @include min($desktop) {
    margin-bottom: 105px;
  }
}

.partner-container {

}

.partner-brow {
  margin-bottom: 25px;

  font-weight: 500;
  font-size: 1.5rem;
  @include letter-spacing(50);
  line-height: 1.6;
  text-transform: uppercase;

  @include min($desktop) {
    margin-bottom: 50px;

    font-size: 2.1rem;
    line-height: 1.4;
  }
}

.partner-lockup {
  margin-bottom: 35px;

  @include min($tablet) {
    display: flex;
    align-items: center;
  }
}

.partner-lockup-image {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;

  border-radius: 50%;
  background-image: url("../assets/images/pages/leadership/image.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @include min($tablet) {
    margin-right: 25px;
    margin-bottom: 0;
  }

  @include min($desktop) {
    width: 130px;
    height: 130px;
    margin-right: 30px;
  }
}

.partner-lockup-copy {
  font-weight: 500;
  font-size: 2.1rem;
  @include letter-spacing(-15);
  line-height: 1.2;

  @include min($desktop) {
    font-size: 2.6rem;
  }
}

.partner-bio {
  font-size: 2.2rem;
  @include letter-spacing(-15);
  line-height: 1.35;

  @include min($desktop) {
    font-size: 3.2rem;
  }
}
