.transactions {
  background-color: #9BB7C6;

  color: $blue-dark;
}

.transactions {
  @import "example";
  @import "hero";
}
