.leadership {
  background-color: #9BB7C6;

  color: $blue-dark;
}

.leadership {
  @import "hero";
  @import "partner";
}
